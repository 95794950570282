import Modal from 'components/Modal';
import useModalStack from 'components/Modal/useModalStack';
import React, { FC } from 'react';
import { styled } from 'styled-components';

interface RemoveModalProps {
  onRemove: () => void;
  name?: string;
  title: string;
}
const ModalWrapper = styled(Modal)`
  min-width: 500px;
  padding: 0;
  gap: 10px;
`;
const Content = styled.div`
  padding-left: 2.5rem;
  width: 80%;
`;
const RemoveModal: FC<RemoveModalProps> = ({ onRemove, name, title }) => {
  const modal = useModalStack();

  return (
    <ModalWrapper
      noPadding
      title={title}
      buttons={[
        {
          label: 'Yes',
          onClick: onRemove,
        },
        {
          label: 'No',
          onClick: () => modal.pop(),
        },
      ]}
    >
      <Content>
        <p>{name}</p>
      </Content>
    </ModalWrapper>
  );
};

export default RemoveModal;

