import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSpecialZonePickerValues } from 'store/ui/uiutil';
import { getDevEnvironment } from 'store/uiSettings/selectors';
import { Zones } from 'store/zone/reducer';
import { selectAvailableZones } from 'store/zone/selector';

export enum ValueType {
  Int = 1,
  String = 2,
  Bool = 3,
}

export enum SegmentType {
  'DriveUp' = 1,
  'Booking' = 2,
}
// Kan ligga i rules context
export type Rule = {
  id?: number;
  name?: string;
  facilityId?: number;
  valueType?: ValueType;
  configurationType?: number;
  instruction?: string;
  level?: number;
};

export type RuleCommon = {
  id: number;
  segment: number;
  value: string;
  configurationId: number;
};
// Zonen styr över grupperna
export interface ZoneRule extends RuleCommon {
  id: number; // Använd för delete
  dynZoneId: number; // zone id
  configurationId: number; // Rule id
  value: string;
  groupId?: number; // Group id .om den används i en grupp finns ej i zone
  segment: number;
}

export interface GroupRule extends RuleCommon {
  id: number;
  groupId: number;
  configurationId: number; // Rule id
  value: string;
  segment: number;
}

export type Group = {
  name: string;
  id: number;
  zoneIds: string[];
  rules: GroupRule[];
  facilityId: number;
};

export type ConfigContextType = {
  groups: Group[];
  getZones: () => Zones;
  rules: Rule[];
  setGroups: React.Dispatch<React.SetStateAction<Group[]>>;
  setZones: (zones: Zones) => void;
  setRules: (rules: Rule[]) => void;
  setFacilityId: (facilityId: string) => void;
  editGroup: (updatedGroup: Group) => void;
  createGroup: (newGroup: Group) => void;
  deleteGroup: (groupId: number) => void;
  addZoneRule: (zoneId: number, updatedRules: ZoneRule[]) => void;
  isLoading: boolean;
};

// Editable gorup i context - on i create / annars edit - skicka med i modal
export const ConfigContext = React.createContext<ConfigContextType>(null!);

export const ConfigContextProvider: React.FC = ({ children }) => {
  const availableZones = useSelector(selectAvailableZones);
  const [groups, setGroups] = useState<Group[]>([]);
  const [zones, setZones] = useState<Zones>([]);
  const [rules, setRules] = useState<Rule[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [facilityId, setFacilityId] = useState<string>('');
  const [zoneRules, setZoneRules] = useState<{ [zoneId: number]: ZoneRule[] }>(
    {}
  );
  // var isDevelopment = useSelector(getDevEnvironment);

  // Fetch groups
  const fetchGroups = async (facilityId: string) => {
    setIsLoading(true);
    // Removed for release
    // if (!isDevelopment) {
    //   setGroups(getSpecialZonePickerValues(facilityId));
    //   return;
    // }
    try {
      const response = await fetch(
        `/api/Configuration/${facilityId}/get/groups`
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch groups: ${response.statusText}`);
      }
      const data: Group[] = await response.json();
      setGroups(data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching groups:', error);
      alert('Failed to fetch group data. Please try again later.');
    }
  };

  // Fetch rules
  const fetchRules = async () => {
    try {
      const response = await fetch(`/api/Configuration/get/rules`);

      if (!response.ok) {
        throw new Error(`Failed to fetch rules: ${response.statusText}`);
      }

      const data: Rule[] = await response.json();
      setRules(data);
    } catch (error) {
      console.error('Error fetching groups:', error);
      alert('Failed to fetch group data. Please try again later.');
    }
  };

  // Create new group
  const createGroup = async (newGroup: Group) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `/api/Configuration/${facilityId}/create/group`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newGroup),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to create group: ${response.statusText}`);
      }
      const createdGroup: Group = await response.json();
      setGroups((prevGroups) => [...prevGroups, createdGroup]);
      updateZoneByGroup(createdGroup);
      setIsLoading(false);
    } catch (error) {
      console.error('Error creating group:', error);
      alert('Failed to create the group. Please try again later.');
    }
  };

  // Edit group
  const editGroup = async (updatedGroup: Group) => {
    setIsLoading(true);
    console.log(updatedGroup, 'Test EditGroup');
    try {
      const response = await fetch(
        `/api/Configuration/${facilityId}/edit/group`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedGroup),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to update group: ${response.statusText}`);
      }
      const responseGroup: Group = await response.json();
      console.log(responseGroup, 'Response EditGroup');
      setGroups((prevGroups) =>
        prevGroups.map((group) =>
          group.id === responseGroup.id ? responseGroup : group
        )
      );
      updateZoneByGroup(responseGroup);
      setIsLoading(false);
    } catch (error) {
      console.error('Error updating group:', error);
    }
  };

  const getZones = () => {
    return zones.map((zone) => {
      if (!!zoneRules[zone.id]) {
        zone.rules = zoneRules[zone.id];
      }
      return zone;
    });
  };

  // Delete group
  const deleteGroup = async (groupId: number) => {
    setIsLoading(true);
    let group = groups.find((group) => group.id === groupId);
    try {
      const response = await fetch(
        `/api/Configuration/${facilityId}/delete/group/${groupId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const updatedGroups: Group[] = await response.json();
      if (!!group) {
        group.rules = [];
        updateZoneByGroup(group);
      }
      setGroups(updatedGroups);
      setIsLoading(false);
    } catch (error) {
      console.error('Fail to delete!', error);
    }
  };

  const addZoneRule = async (zoneId: number, updatedRules: ZoneRule[]) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `/api/Configuration/${facilityId}/edit/${zoneId}/zone`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedRules),
        }
      );
      const ruleList: ZoneRule[] = await response.json();
      setZoneRules({
        ...zoneRules,
        [zoneId]: ruleList,
      });
      setIsLoading(false);
    } catch (error) {
      console.error('Fail to add rule!', error);
    }
  };
  const updateZoneByGroup = (group: Group) => {
    let _zoneRules = zoneRules;
    group.zoneIds.forEach((zoneId) => {
      var zone = zones.find((f) => f.gid === zoneId); // Hämtar ut zonen som finns i gruppen
      let groupRules = [...group.rules]; //Skapar en shallow copy av alla group rules för att inte arbeta med orginal listan
      if (!!zone) {
        //Går igenom alla zoneregler för att skapa en ny uppdaterad regellista
        let newZoneRules: ZoneRule[] = zone.rules!.reduce((acc, rule) => {
          //Tillhör regeln den editerade/skapade gruppen?
          if (rule.groupId === group.id) {
            //Kollar om regeln fortfarande finns kvar i gruppen (Om borttagen returnera null)
            var groupRule = groupRules.find(
              (f) =>
                f.configurationId == rule.configurationId &&
                f.segment == rule.segment
            );
            if (!!groupRule) {
              //Om regeln finns så ska den läggas till i listan
              groupRules = groupRules.filter((f) => f.id !== groupRule!.id); //Tar bort regeln från grupp listan för att visa att vi har behandlat regeln
              if (groupRule.value !== rule.value)
                //Kollar om vi måste uppdatera värdet på zone regeln utifrån gruppen
                rule.value = groupRule.value; //Uppdaterar vöärder
              acc.push(rule); //Lägger till zonen i vår ny lista
            } // OM REGELN INTE FINNS I GROUP LÄNGRE SÅ KOMMER VI INTE LÄGGA TILL DEN I NYA LISTAN
            return acc;
          }
          return [...acc, rule];
        }, [] as ZoneRule[]);

        //Eftersom vi har tagit bort de regler som redan fanns i zonen har vi bara regler
        //kvar som inte fanns i zonen, dvs NYA regler. Då kan vi kompletera zoneRules listan
        //med de regler som finns kvar i GroupRules. Borttagning av regler på rad 212
        newZoneRules = [
          ...newZoneRules,
          ...groupRules.map((f) => {
            return {
              ...f,
              dynZoneId: zone!.id,
            } as ZoneRule;
          }),
        ];
        _zoneRules = {
          ..._zoneRules,
          [zone!.id]: newZoneRules,
        };
      }
    });
    setZoneRules(_zoneRules);
  };

  

  useEffect(() => {
    if (!!facilityId) {
      const filtered = availableZones.filter(
        (zone) => zone.sitePath === facilityId
      );
      setZones(filtered);
      fetchGroups(facilityId);
      fetchRules();
    }
  }, [facilityId, availableZones]);
  return (
    <ConfigContext.Provider
      value={{
        groups,
        getZones,
        rules,
        setGroups,
        setZones,
        setRules,
        setFacilityId,
        editGroup,
        createGroup,
        deleteGroup,
        addZoneRule,
        isLoading,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfigContext = () => useContext(ConfigContext);

