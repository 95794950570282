import * as Actions from './action';
import { User } from 'pages/admin/Users/userAdminContext';
import { ActionReturns } from '../../util/tshelpers';
import { UISETTINGS } from './constants';
import { Pricing, ZoneConfig, PriceSchedule } from 'store/zone/reducer';
import { Events } from 'store/event/definitions';
import { ZoneRule } from 'pages/Configuration/contexts/configurationContext';

export interface PriceProductExport {
  id: number;
  dateModified: Date;
  exporterType: string;
  exporterTargetID: string;
  sourceDZoneID: number;
  pricingSourceID: number | undefined;
  exporterTargetApiCredentialsID: number | undefined;
  zoneName: string | undefined;
  priceName: string | undefined;
}

export interface SiteZones {
  zoneId: number;
  gid: string;
  routePath: string;
  zoneName: string;
  enabledPriceTypes: string[]; // can contain "hour" , "hour_min" , "hour_max", "day", "day_min" , "day_max", "week", "week_min" and/or "week_max"
  exports: PriceProductExport[];
  config?: ZoneConfig;
  rules: ZoneRule[];
}

export enum FacilityFlag {
  NoFutureGraphDisplay = 1,
}
export interface Facility {
  segments: string[];
  path: string;
  name: string;
  id: number;
  facilityFlags?: FacilityFlag[];
  config?: {
    electricPricingLocation?: string;
    currencyDecimals?: number;
    grossIncludedTaxRatePercentage?: number;
  };
}

export interface UISettings {
  env: string;
  user: User;
  isDev: boolean;
  userOrg: string;
  hasUserAdmin: boolean;
  sites: Facility[];
  siteZones: SiteZones[];
  siteWrite: number[];
  siteAdmin: number[];
  orgAdmin: number[];
  isAPDemo: boolean;
  events: Events;
  pricings: Pricing[];
  schedules: PriceSchedule[];
}

const initialState: UISettings = {
  user: new User(),
  isDev: false,
  userOrg: '',
  hasUserAdmin: false,
  sites: [],
  events: [],
  siteAdmin: [],
  siteWrite: [],
  orgAdmin: [],
  siteZones: [],
  isAPDemo: true,
  pricings: [],
  schedules: [],
  env: '',
};

type Actions = ActionReturns<typeof Actions>;


export function reducer(state = initialState, action: Actions): UISettings {
  switch (action.type) {
    case UISETTINGS.UPDATE_USER:
      return { ...state, user: action.user };
    case UISETTINGS.UPDATE_ZONE_SCHDULE:
      var _schedule = state.schedules.reduce((acc, curr) => {
        var sch = action.schedule.find((s) => s.gid === curr.gid);
        if (!!sch) {
          acc.push(sch);
          return acc;
        }
        acc.push(curr);
        return acc;
      }, [] as PriceSchedule[]);
      return { ...state, schedules: _schedule };
  }
  return state;
}
