import {
  Checkbox,
  classNames,
  Input,
  StatelessTable,
} from '@bitio/react-components';
import {
  ColumnDef,
  getCoreRowModel,
  RowData,
  useReactTable,
} from '@tanstack/react-table';
import useModalStack from 'components/Modal/useModalStack';
import TooltipComponent, {
 TooltipMode,
} from 'components/Tooltip';
import {
  RuleCommon,
  SegmentType,
  useConfigContext,
} from 'pages/Configuration/contexts/configurationContext';
import React, { useEffect, useState } from 'react';
import { FaEdit, FaSave, FaTrashAlt } from 'react-icons/fa';
import { styled } from 'styled-components';
import RemoveModal from '../modals/RemoveModal';
import { segmentLabels, valueTypeToInputType } from './AddRule';

// This Extend TableMeta to include updateData which is a built-in function to update data in the table
declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
  }
}

interface RulesTableProps<T> {
  ruleList: T[];
  updateRule: (updatedRule: T) => void;
  deleteRule: (ruleId: number) => void;
}

const CellWrapper = styled.div`
  display: flex;
  gap: 16px;
  color: ${({ theme }) => theme.palette.primary.bg};
`;

const StyledStatelessTable = styled(StatelessTable)`
  .${classNames.tableContainer}.${classNames.tableContainer}.${classNames.tableContainer}.${classNames.tableContainer}.${classNames.tableContainer} {
    font-weight: 600;
    color: ${(props) => props.theme.palette.text.fg};
    overflow: visible !important;
  }

  .${classNames.th}.${classNames.th}.${classNames.th}.${classNames.th}.${classNames.th} {
    font-weight: 600;
    color: ${(props) => props.theme.palette.text.fg};
  }
  .${classNames.tr}.${classNames.tr}.${classNames.tr}.${classNames.tr} {
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      color: ${(props) => props.theme.palette.text.fg};
      background-color: ${(props) => props.theme.palette.common.opacityHover};

      ${CellWrapper} {
        color: ${(props) => props.theme.palette.primaryActive.bg};
      }
    }
    .${classNames.td}.${classNames.td}.${classNames.td}.${classNames.td}.${classNames.td} {
      padding: 5px;
      padding-block: 5px;
      display: flex;
      align-items: center;
      position: relative;
    }
  }
` as typeof StatelessTable;

// Helper function to render the value input based on the type - TODO: MOVE TO seperate file
const RenderValueInput: React.FC<{
  type: 'text' | 'number' | 'checkbox';
  value: string | number | boolean;
  handleChange: (value: unknown) => void;
}> = ({ type, value, handleChange }) => {
  const [valueInput, setValueInput] = React.useState(value);
  const onBlur = () => {
    handleChange(valueInput);
  };
  React.useEffect(() => {}, [valueInput]);

  switch (type) {
    case 'checkbox':
      return (
        <Checkbox
          checked={valueInput as boolean}
          onChange={(e) => setValueInput(e.currentTarget.checked)}
          onBlur={onBlur}
        />
      );
    case 'number':
      return (
        <Input
          type="number"
          value={valueInput.toString()}
          onChange={(e) => setValueInput(Number(e.target.value))}
          onBlur={onBlur}
        />
      );
    case 'text':
    default:
      return (
        <Input
          type="text"
          value={valueInput as string}
          onChange={(e) => setValueInput(e.target.value)}
          onBlur={onBlur}
        />
      );
  }
};
function RulesTable<T extends RuleCommon>({
  ruleList,
  updateRule,
  deleteRule,
}: React.PropsWithChildren<RulesTableProps<T>>): React.ReactElement {
  const { rules } = useConfigContext();
  const [data, setData] = useState<T[]>(ruleList || []);
  const [currentlyEditing, setCurrentlyEditing] = useState<number | null>(null);
  const modal = useModalStack();

  const startEditing = (id: number) => setCurrentlyEditing(id);

  const updateData = (rowIndex: number, columnId: string, value: unknown) => {
    setData((oldData) =>
      oldData.map((row, index) =>
        index === rowIndex ? { ...row, [columnId]: value } : row
      )
    );
  };

  const saveChanges = (updatedRule: T) => {
    if (!updatedRule) return;
    updateRule(updatedRule);
    setCurrentlyEditing(null);
  };

  useEffect(() => {
    if (!ruleList) return;
    setData(ruleList);
  }, [ruleList]);
  const columns: ColumnDef<T>[] = [
    {
      header: 'Rule Name',
      accessorKey: 'name',
      minSize: 250,
      cell: ({ row: { index }, column: { id } }) => {
        const rule = rules.find((r) => r.id === data[index]?.configurationId)!;

        return (
          <div
            style={{
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <p style={{ fontWeight: 500, margin: 0 }}>{rule.name}</p>
            <TooltipComponent
              mode={TooltipMode.HOVER}
              tooltipText={rule.instruction!}
              fixed
            />
          </div>
        );
      },
    },
    {
      header: 'Segment',
      accessorKey: 'segment',
      minSize: 150,
      maxSize: 200,
      cell: ({ getValue, row: { index }, column: { id }, table }) => (
        <option>{segmentLabels[getValue() as SegmentType]}</option>
      ),
    },
    {
      header: 'Value',
      accessorKey: 'value',
      minSize: 200,

      cell: ({ getValue, row: { index }, column: { id }, table }) => {
        const isEditableRow = currentlyEditing === data[index]?.id;
        const configurationId = data[index]?.configurationId;
        const currentRule = rules.find((rule) => rule.id === configurationId);
        valueTypeToInputType[currentRule?.valueType || 1];

        return isEditableRow ? (
          <RenderValueInput
            type={valueTypeToInputType[currentRule?.valueType ?? 1] as any}
            value={getValue() as string | number | boolean}
            handleChange={(newValue) =>
              table.options.meta?.updateData(index, id, newValue)
            }
          />
        ) : (
          <span>{getValue()?.toString() || 'Has no value'}</span>
        );
      },
    },
    {
      header: 'Edit',
      cell: ({
        row: {
          index,
          original: { id },
        },
      }) =>
        currentlyEditing === id ? (
          <CellWrapper>
            <FaSave size="20px" onClick={() => saveChanges(data[index])} />
            <FaTrashAlt
              size="20px"
              onClick={() => {
                modal.push(
                  <RemoveModal
                    title="Really remove this rule?"
                    name={segmentLabels[data[index].segment as SegmentType]}
                    onRemove={() => deleteRule(id)}
                  />
                );
              }}
            />
          </CellWrapper>
        ) : (
          <CellWrapper>
            <FaEdit size="20px" onClick={() => startEditing(id)} />
          </CellWrapper>
        ),
    },
  ];
  const table = useReactTable({
    data,
    columns,
    meta: { updateData },
    getCoreRowModel: getCoreRowModel(),
  });
  return <StyledStatelessTable table={table} asList />;
}

export default RulesTable;

