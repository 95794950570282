import React from 'react';
import Select from 'components/input/Select';
import Label from 'components/input/Label';
import { useDispatch, useSelector } from 'react-redux';
import { uiSetOccupancyMode } from 'store/ui/action';
import { selectUiStateOccupancy } from 'store/ui/selector';

const occupancyOptions = [
  { value: 1, label: 'Day' },
  { value: 2, label: 'Max hour' },
  { value: 3, label: 'AVG hour' },
];

const OccupancyPicker: React.FC = () => {
  const occupancyMode = useSelector(selectUiStateOccupancy);
  const [value, setValue] = React.useState(occupancyMode);
  const dispatch = useDispatch();

  console.log('OccupancyPicker', occupancyMode);
  return (
    <Label label="Parkings per:" row>
      <Select
        value={value}
        onChange={(e) => {
          const newValue = parseInt(e.target.value) as 1 | 2 | 3;
          setValue(newValue);
          dispatch(uiSetOccupancyMode(newValue));
        }}
      >
        {occupancyOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </Label>
  );
};

export default OccupancyPicker;

