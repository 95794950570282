import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { AiOutlineCheck } from 'react-icons/ai';
import { BsChevronDown } from 'react-icons/bs';
import { ButtonColors } from 'components/input/Button';
import { Dropdown } from 'components/input/Dropdown';

export interface ZoneSelectorOption {
  id: string;
  name: string;
}

interface ZoneMultiSelectorProps {
  options: ZoneSelectorOption[];
  selected: ZoneSelectorOption[];
  onChange: (selected: ZoneSelectorOption[]) => void;
  scrollable?: boolean;
  maxHeight?: string;
  placeholder?: string;
}

export const ZoneDropdownWrapper = styled(Dropdown.Wrapper)`
  z-index: 3;
`;

export const ZoneDropdown = styled(Dropdown.Select)`
  z-index: 1000;
`;

export const ZoneDropdownToggle = styled(Dropdown.Toggle)`
  z-index: 3;
`;
const ZoneMultiSelector: React.FC<ZoneMultiSelectorProps> = ({
  options,
  selected,
  onChange,
  scrollable = true,
  maxHeight = '300px',
  placeholder = 'Choose option',
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleSelected = (option: ZoneSelectorOption) => {
    const alreadySelected = selected.find((item) => item.id === option.id);
    const newSelected = alreadySelected
      ? selected.filter((item) => item.id !== option.id)
      : [...selected, option];
    onChange(newSelected);
    setIsOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <ZoneDropdownWrapper ref={wrapperRef}>
      <ZoneDropdownToggle
        onClick={() => setIsOpen((prev) => !prev)}
        aria-expanded={isOpen}
        aria-haspopup="listbox"
      >
        {placeholder} <BsChevronDown size="12px" />
      </ZoneDropdownToggle>
      {isOpen && (
        <ZoneDropdown
          role="listbox"
          $scrollable={scrollable}
          $maxHeight={maxHeight}
          aria-multiselectable="true"
        >
          {options.map((option) => (
            <Dropdown.Option
              key={option.id}
              $isSelected={!!selected.find((item) => item.id === option.id)}
              onClick={(e) => {
                e.stopPropagation();
                toggleSelected(option);
              }}
              aria-selected={!!selected.find((item) => item.id === option.id)}
              role="option"
            >
              <span>{option.name}</span>
              {selected.find((item) => item.id === option.id) && (
                <AiOutlineCheck color={ButtonColors.primary} />
              )}
            </Dropdown.Option>
          ))}
        </ZoneDropdown>
      )}
    </ZoneDropdownWrapper>
  );
};

export default ZoneMultiSelector;

