import styled, { css } from 'styled-components';

export enum ButtonColors {
  primary = 'primary',
  primaryGreen = 'primary-green',
  secondary = 'secondary',
  add = 'add',
  link = 'link',
  remove = 'remove',
}

interface ButtonProps {
  color?: ButtonColors;
  disabled?: boolean;
}

const Button = styled.button<ButtonProps>`
  ${(props) => {
    const { color = ButtonColors.primary, theme, disabled } = props;
    let palette = theme.palette.primary;
    let hoverPalette = theme.palette.primaryActive;

    switch (color) {
      case ButtonColors.primary:
        palette = theme.palette.primary;
        hoverPalette = theme.palette.primaryActive;
        break;

      case ButtonColors.primaryGreen:
        palette = theme.palette.secondary;
        hoverPalette = theme.palette.secondaryActive;
        break;

      case ButtonColors.secondary:
        palette = {
          bg: 'transparent',
          fg: theme.palette.common.black,
        };
        hoverPalette = {
          bg: 'transparent',
          fg: theme.palette.text.fg,
        };
        break;

      case ButtonColors.remove:
        palette = theme.palette.error;
        hoverPalette = theme.palette.errorActive;
        break;

      case ButtonColors.add:
        palette = theme.palette.secondary;
        hoverPalette = theme.palette.primaryActive;

        return css`
          outline: none;
          display: inline-flex;
          flex-direction: row;
          align-items: center;
          position: relative;
          bottom: -0.7em;

          ${disabled && 'opacity: 0.35;'}

          border: 0;
          background-color: transparent;
          color: ${palette.fg};
          font-weight: 700;
          transition: background-color 0.2s;

          &::before {
            content: '+';
            display: flex;
            align-items: center;
            justify-content: center;
            width: 0.9em;
            height: 0.9em;
            margin: auto 0;
            margin-right: 10px;
            overflow: hidden;

            border-radius: 1em;
            font-family: 'Source Sans 3', sans-serif;
            font-size: 2.5em;
            font-weight: 300;
            background-color: ${palette.bg};
            transition: color 0.2s, background-color 0.2s;
          }

          &:hover {
            text-decoration: underline;
            text-decoration-color: ${palette.bg};
            text-decoration-thickness: 2px;
            text-underline-position: under;

            &::before {
              background-color: ${hoverPalette.bg};
              color: ${hoverPalette.fg};
            }
          }

          &:focus {
            &::before {
              box-shadow: 0 0 0 1px ${theme.palette.text.bg},
                0 0 0 3px ${theme.palette.primary.bg};
            }
          }
        `;

      case ButtonColors.link:
        return css`
          margin: 0;
          padding: 0;
          border: 0;
          background: transparent;
          font-weight: 600;
          color: ${theme.palette.primary.bg};
          transition: color 0.2s;

          &:hover {
            color: ${theme.palette.primary.bg};
            text-decoration: underline;
            text-decoration-color: ${theme.palette.secondary.bg};
            text-decoration-thickness: 2px;
            text-underline-position: under;
          }
        `;
    }

    return css`
      padding: 5px 35px;

      ${disabled && 'opacity: 0.35;'}

      border-radius: 64px;
      border: 0;
      background-color: ${palette.bg};
      color: ${palette.fg};
      font-weight: 600;
      transition: background-color 0.2s;

      ${color === ButtonColors.secondary &&
      css`
        border: 2px solid ${palette.fg};
      `}

      &:hover {
        background-color: ${hoverPalette.bg};
        color: ${hoverPalette.fg};
      }

      &:focus {
        outline: 0;
        box-shadow: 0 0 0 1px ${theme.palette.text.bg},
          0 0 0 3px ${theme.palette.primary.bg};
      }
    `;
  }}
`;

export const RoundButton = styled(Button)`
  padding: 5px;
  width: 35px;
  height: 35px;
  border-radius: 5000px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export default Button;
