import React from 'react';
import Modal from 'components/Modal';
import {
  useConfigContext,
  ZoneRule,
} from '../../contexts/configurationContext';
import Label from 'components/input/Label';
import styled from 'styled-components';
import RulesTable from '../tables/RulesTable';
import AddRule from '../tables/AddRule';
import Loader from 'components/Loader';
import useModalStack from 'components/Modal/useModalStack';
import { Zone } from 'store/zone/reducer';
import { StyledTable } from '../ConfigGroups';

interface ZoneModalProps {
  editZone?: Zone;
}
const ModalWrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 2.5rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
`;
const TopColumn = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const BottomColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SectionLabel = styled(Label)`
  font-weight: 600;
  fontSize: 20px;
  color: ${(props) => props.theme.palette.primaryActive.bg};
  };
`;

const ZoneModal: React.FC<ZoneModalProps> = ({ editZone }) => {
  const { isLoading, addZoneRule, rules, groups } = useConfigContext();
  const modal = useModalStack();
  const [zoneRules, setZoneRules] = React.useState<ZoneRule[]>(
    editZone?.rules?.filter((rules) => !rules.groupId) || []
  );

  // const zoneGroupRules = editZone?.rules?.filter((rules) => !!rules.groupId).map((rule) => rule.groupId)
  // Helper function to get rule name
  const getRuleName = (configurationId: number): string => {
    const matchedRule = rules.find((r) => r.id === configurationId);
    return matchedRule?.name || 'Unknown Rule'; // Fallback if no match is found
  };

  // Filter and map logic
  const filteredRuleNames = editZone?.rules
    ?.filter((zoneRule) => !!zoneRule.groupId) // Only include rules with groupId
    .map((zoneRule) => ({
      id: zoneRule.id,
      name: getRuleName(zoneRule.configurationId),
      groupName: groups.find((group) => group.id === zoneRule.groupId)?.name,
      segment: zoneRule.segment,
      value: zoneRule.value,
    }));

  const handleAddZoneRule = (
    ruleId: number,
    segment: number,
    value: string
  ) => {
    if (
      zoneRules.some(
        (rule) => rule.segment === segment && rule.configurationId === ruleId
      )
    ) {
      return alert('Rule already exists');
    } else {
      const newRule: ZoneRule = {
        id: 0,
        configurationId: ruleId,
        dynZoneId: editZone?.id || 0,
        segment,
        value,
      };
      setZoneRules((prev) => [...prev, newRule]);
    }
  };

  const updateZoneRule = (updatedRule: ZoneRule) => {
    setZoneRules((prev) =>
      prev.map((rule) => (rule.id === updatedRule.id ? updatedRule : rule))
    );
  };

  const deleteZoneRule = (ruleId: number) => {
    setZoneRules((prev) => prev.filter((rule) => rule.id !== ruleId));
  };

  const handleSubmit = async () => {
    addZoneRule(editZone?.id || 0, zoneRules);
    modal.pop();
  };

  return (
    <ModalWrapper
      title={editZone?.name}
      buttons={[
        {
          label: 'Save Changes',
          onClick: handleSubmit,
          dontPopAfterClick: true,
        },
      ]}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <Wrapper>
          {filteredRuleNames && filteredRuleNames.length > 0 ? (
            <TopColumn>
              <SectionLabel label="Rules applied from groups:" />
              <StyledTable
                asList
                className=""
                data={filteredRuleNames || []}
                columns={[
                  {
                    header: 'Group Name',
                    accessorKey: 'name',
                    minSize: 220,
                    maxSize: 250,
                  },
                  {
                    header: 'Rule',
                    accessorKey: 'groupName',
                    minSize: 150,
                    maxSize: 200,
                  },
                  {
                    header: 'Segment',
                    accessorKey: 'segment',
                    minSize: 150,
                    maxSize: 200,
                  },
                  {
                    header: 'Value',
                    accessorKey: 'value',
                    minSize: 150,
                    maxSize: 200,
                  },
                ]}
              />
            </TopColumn>
          ) : null}

          <BottomColumn>
            <h5>Add group rules</h5>
            <AddRule createRule={handleAddZoneRule} ruleList={zoneRules} />
            <RulesTable
              ruleList={zoneRules}
              updateRule={updateZoneRule}
              deleteRule={deleteZoneRule}
            />
          </BottomColumn>
        </Wrapper>
      )}
    </ModalWrapper>
  );
};

export default ZoneModal;

