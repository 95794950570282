import React, { useEffect } from 'react';
import Select from 'components/input/Select';
import {
  Rule,
  RuleCommon,
  SegmentType,
  useConfigContext,
} from 'pages/Configuration/contexts/configurationContext';
import { styled } from 'styled-components';
import Button, { ButtonColors } from 'components/input/Button';
import Input from 'components/input/Input';
import Label from 'components/input/Label';
import RuleSelector from '../RuleSelector';

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  padding-bottom: 20px;
`;
export const segmentOptions: SegmentType[] = [
  SegmentType.DriveUp,
  SegmentType.Booking,
];

export const segmentLabels: { [key in SegmentType]: string } = {
  [SegmentType.DriveUp]: 'Drive Up',
  [SegmentType.Booking]: 'Booking',
};

export const valueTypeToInputType = {
  1: 'number',
  2: 'text',
  3: 'checkbox',
};

interface AddRuleProps<T> {
  createRule: (ruleId: number, segment: number, value: string) => void;
  ruleList: T[];
}
function AddRule<T extends RuleCommon>({
  ruleList,
  createRule,
}: React.PropsWithChildren<AddRuleProps<T>>): React.ReactElement {
  const { rules } = useConfigContext();
  const [selectedRule, setSelectedRule] = React.useState<Rule | undefined>(
    rules[0]
  );
  const [segment, setSegment] = React.useState<number>(1);
  const [valueInput, setValueInput] = React.useState<string | boolean | number>(
    ''
  );
  const [activeSegments, setActiveSegments] =
    React.useState<SegmentType[]>(segmentOptions);

  const [isAllApplied, setIsAllApplied] = React.useState<boolean>(false);

  const handleAddRule = () => {
    createRule(selectedRule?.id || 0, segment, valueInput.toString());

    setSelectedRule(undefined);
    setSegment(0);
    setValueInput('');
  };

  const isAllRulesCombinationsApplied = (): boolean => {
    console.log('jag körs');
    return rules.every((rule) =>
      segmentOptions.every((segment) =>
        ruleList.some(
          (r) => r.configurationId === rule.id && r.segment === segment
        )
      )
    );
  };

  useEffect(() => {
    if (ruleList.some((rule) => rule.configurationId === selectedRule?.id)) {
      setActiveSegments(
        segmentOptions.filter(
          (seg) =>
            !ruleList.some(
              (rule) =>
                rule.configurationId === selectedRule?.id &&
                rule.segment === seg
            )
        )
      );
    } else {
      setActiveSegments(segmentOptions);
    }
  }, [ruleList, selectedRule]);

  useEffect(() => {
    if (activeSegments.length === 1) {
      setSegment(activeSegments[0]);
    }
  }, [activeSegments]);

  useEffect(() => {
    if (rules.length > 0) {
      setSelectedRule(rules[0]);
    }
    setIsAllApplied(isAllRulesCombinationsApplied());
  }, [ruleList, isAllApplied]);

  return (
    <Wrapper>
      <Label label="Rule">
        <RuleSelector
          disabled={isAllApplied}
          options={rules.map((rule) => ({
            id: rule.id ?? 0,
            name: rule.name ?? 'Unnamed Rule',
            info: rule.instruction,
          }))}
          value={selectedRule?.id ?? 0}
          onChange={(id) => {
            setSelectedRule(rules.find((rule) => rule.id === id));
          }}
          placeholder={'Select a rule'}
        />
      </Label>
      <Label label="Segment">
        <Select
          disabled={selectedRule === undefined || isAllApplied}
          value={segment}
          onChange={(e) => setSegment(parseInt(e.target.value))}
        >
          <option value="">Select segment</option> 
          {activeSegments.map((seg, idx) => (
            <option key={`segment-${seg}-${idx}`} value={seg}>
              {segmentLabels[seg]}
            </option>
          ))}
        </Select>
      </Label>
      <Label label="Value">
        {selectedRule?.valueType === 3 ? (
          <Input
            disabled={segment === 0}
            type="checkbox"
            checked={Boolean(valueInput)}
            onChange={(e) => setValueInput(e.target.checked)}
          />
        ) : (
          <Input
            disabled={segment === 0}
            type={valueTypeToInputType[selectedRule?.valueType || 1]}
            value={valueInput as string | number}
            onChange={(e) => {
              const newValue =
                selectedRule?.valueType === 1
                  ? parseInt(e.target.value, 10)
                  : e.target.value;
              setValueInput(newValue);
            }}
          />
        )}
      </Label>
      <Button
        disabled={segment === 0 || valueInput === ''}
        style={{ padding: '0' }}
        color={ButtonColors.add}
        onClick={handleAddRule}
      >
        Add Rule
      </Button>
    </Wrapper>
  );
}

export default AddRule;

