import React, { useEffect, useState } from 'react';
import Modal from './Modal';
import Button from './input/Button';
import { useSelector } from 'react-redux';
import { selectAvailableFacilityZones } from 'store/zone/selector';
import Label from './input/Label';
import Select from './input/Select';
import styled from 'styled-components';
import { PriceSchedule, Pricing, Zone } from 'store/zone/reducer';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: normal;
  width: 100%;
  gap: 20px;
`;

interface Props {
  cardPricing: Pricing;
}

const CopyPriceProductModal: React.FC<Props> = ({ cardPricing }) => {
  const availableZones = useSelector(selectAvailableFacilityZones);
  const [selectedZone, setSelectedZone] = useState<Zone>();
  const [avaialbleSchedules, setAvailableSchedules] = useState<PriceSchedule[]>(
    []
  );
  const [selectedSchedule, setSelectedSchedule] = useState<string>('');
  const [availablePriceProducts, setAvailablePriceProducts] = useState<
    Pricing[]
  >([]);
  const [selectedPriceProduct, setSelectedPriceProduct] = useState<number>(-1);

  const handleCopyClick = () => {
    const requestOptions = {
      cardPriceId: cardPricing.id,
      zone: selectedZone?.id,
      schedule: selectedSchedule,
      priceProduct: selectedPriceProduct,
    };
  };

  useEffect(() => {
    if (selectedZone) {
      setAvailableSchedules(selectedZone.schedules);
    } else {
      setAvailableSchedules([]);
      setSelectedSchedule('');
    }
    setSelectedPriceProduct(-1);
    setAvailablePriceProducts([]);
  }, [selectedZone]);

  useEffect(() => {
    if (selectedSchedule) {
      setAvailablePriceProducts(
        avaialbleSchedules.find((schedule) => schedule.gid === selectedSchedule)
          ?.pricings || []
      );
    } else {
      setAvailablePriceProducts([]);
    }
    setSelectedPriceProduct(-1);
  }, [avaialbleSchedules, selectedSchedule, selectedZone]);

  return (
    <Modal
      title={'Construct a Price Product'}
      buttons={[
        {
          label: 'Copy',
          onClick: handleCopyClick,
          disabled:
            !selectedZone ||
            selectedSchedule === '' ||
            selectedPriceProduct === -1,
        },
      ]}
    >
      <Content>
        <h3>
          Timespan: {cardPricing.startDate} - {cardPricing.endDate}
        </h3>
        {!!availableZones && (
          <Label label="1. Select a zone you want to apply the pricing's timespan for">
            <Select
              onChange={(e) => {
                setSelectedZone(
                  availableZones.find(
                    (zone) => zone.id === parseInt(e.target.value)
                  ) || undefined
                );
              }}
              value={selectedZone?.id}
            >
              <option value={-1}>Select a zone</option>
              {availableZones.map((zone) => (
                <option key={`zone-${zone.id}`} value={zone.id}>
                  Zone: {zone.name}
                </option>
              ))}
            </Select>
          </Label>
        )}

        {!!selectedZone && !!avaialbleSchedules && (
          <Label label="2. Select the zone schedule that the pricing should be applied to">
            <Select
              onChange={(e) => {
                setSelectedSchedule(e.target.value);
              }}
              value={selectedSchedule}
            >
              <option value={''}>Select a schedule</option>
              {avaialbleSchedules.map((schedule) => (
                <option key={`schedule-${schedule.gid}`} value={schedule.gid}>
                  Scedule: {schedule.name}
                </option>
              ))}
            </Select>
          </Label>
        )}

        {!!selectedZone &&
          !!selectedSchedule &&
          !!availablePriceProducts.length && (
            <Label label="3. Select a Price Product to copy the prices from">
              <Select
                onChange={(e) => {
                  setSelectedPriceProduct(parseInt(e.target.value));
                }}
                value={selectedPriceProduct}
              >
                <option value={-1}>Select a price product</option>
                {availablePriceProducts.map((priceProduct) => (
                  <option key={`pp-${priceProduct.id}`} value={priceProduct.id}>
                    Price Product: {priceProduct.name}
                  </option>
                ))}
              </Select>
            </Label>
          )}
      </Content>
    </Modal>
  );
};

export default CopyPriceProductModal;
