import React from 'react';
import { useConfigContext } from '../contexts/configurationContext';
import { classNames, ReactTable } from '@bitio/react-components';
import Button from 'components/input/Button';
import useModalStack from 'components/Modal/useModalStack';
import GroupModal from './modals/GroupModal';
import RemoveModal from './modals/RemoveModal';
import { styled } from 'styled-components';
import Loader from 'components/Loader';

const CellWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const TableButton = styled(Button)`
  padding: 2px 25px;
  font-size: 14px;
  outline: 1px solid ${(props) => props.theme.palette.secondaryActive.fg};
  outline-offset: -1px;
  background-color: ${(props) => props.theme.palette.common.white};
  transition: color 0.3s ease, background-color 0.3s ease,
    outline-color 0.3s ease;
  color: ${(props) => props.theme.palette.secondaryActive.fg};

  &:hover {
    color: ${(props) => props.theme.palette.primary.fg};
    background-color: ${(props) => props.theme.palette.primary.bg};
    outline-color: ${(props) => props.theme.palette.primary.fg};
  }
  &:focus {
    outline: 2px solid ${(props) => props.theme.palette.primary.fg};
    outline-offset: 0px;
  }
`;
export const StyledTable = styled(ReactTable)`
  .${classNames.th}.${classNames.th}.${classNames.th}.${classNames.th}.${classNames.th} {
    font-weight: 600;
    color: ${(props) => props.theme.palette.text.fg};
  }
  .${classNames.tr}.${classNames.tr}.${classNames.tr}.${classNames.tr} {
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      color: ${(props) => props.theme.palette.text.fg};
      background-color: ${(props) => props.theme.palette.common.opacityHover};

      ${TableButton} {
        color: ${(props) => props.theme.palette.primary.fg};
        background-color: ${(props) => props.theme.palette.primary.bg};
        outline-color: ${(props) => props.theme.palette.primary.fg};
      }
    }
  }
` as typeof ReactTable;
const ConfigGroups: React.FC = () => {
  const { groups, isLoading, deleteGroup } = useConfigContext();
  const modal = useModalStack();

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <StyledTable
          asList
          className=""
          data={groups}
          columns={[
            { header: 'Group Name', accessorKey: 'name' },
            { header: 'Zones', accessorFn: (row) => row.zoneIds.length },
            {
              header: 'Edit',
              accessorKey: 'id',
              cell: (props) => (
                <CellWrapper>
                  <TableButton
                    onClick={() => {
                      modal.push(
                        <GroupModal editableGroup={props.row.original} />
                      );
                    }}
                  >
                    Edit
                  </TableButton>

                  <TableButton
                    onClick={() => {
                      modal.push(
                        <RemoveModal
                          title={`Delete this group?`}
                          onRemove={() => deleteGroup(props.row.original.id)}
                          name={`Are you sure you wish to delete this ${props.row.original.name} group?`}
                        />
                      );
                    }}
                  >
                    Delete
                  </TableButton>
                </CellWrapper>
              ),
            },
          ]}
        />
      )}
    </>
  );
};

export default ConfigGroups;

