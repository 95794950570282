import React from 'react';
import Select from 'components/input/Select';
import { useDispatch, useSelector } from 'react-redux';
import { selectHistoryFacilityMatch } from 'store/router/selector';
import { getUser, selectUISettingSites } from 'store/uiSettings/selectors';
import styled from 'styled-components';
import { FacilityPageProps } from 'topdefinitions';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import { ApplicationState } from 'store/definitions';
import { facilityChangeActive, zoneChangeActive } from 'store/ui/action';
import useLocalStorage from 'provider/useLocalStorage';

export const facilityLocalStorageKey = "locationKey"
import Label from 'components/input/Label';

const SelectWrapper = styled.div``;

const FacilityPicker: React.FC = () => {
  const { write} = useLocalStorage();
  var user = useSelector(getUser);
  const routeInfo = useSelector(selectHistoryFacilityMatch);
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch<FacilityPageProps['match']['params']>();
  const zoneSites = useSelector(
    (state: ApplicationState) => state.uiSettings.siteZones
  );
  const globalFacilities = useSelector(selectUISettingSites);
  var facilities = user.facilities;

  const value = routeInfo?.params.facilityId;
  if (facilities.length <= 1) {
    return null;
  }

  return (
    <Label label="Facility:" row>
      <Select
        value={value}
        onChange={(e) => {
          if (e.target.value === '') return;
          const facility = facilities.find(
            (f) => f.id === Number(e.target.value)
          );

          var pathData = {
            facilityId: e.target.value,
          } as { facilityId: string; zoneId?: number };
          var facilityZones = zoneSites.filter(
            (f) => f.routePath === e.target.value
          );

          var zones = facilityZones.map((f) => f.zoneId);
          if (facilityZones.length > 5) {
            zones = [facilityZones[0].zoneId];
          }

          var oldPath = generatePath(match?.path!, match?.params);
          var diff = history.location.pathname.replace(
            new RegExp(oldPath, 'g'),
            ''
          );

          var path = generatePath(`${match?.path!}${diff}`, pathData);
          history.push(path);

          //console.log(e.target.value, globalFacilities);
          const uiFac = globalFacilities.find(
            (fi) => fi.path === e.target.value
          )!;

          dispatch(zoneChangeActive(zones));
          dispatch(
            facilityChangeActive(
              e.target.value,
              uiFac.segments.length > 1 ? 'all' : uiFac.segments[0]
            )
          );
          write(facilityLocalStorageKey, e.target.value);
        }}
      >
        {facilities.map((f) => (
          <option key={f.id} value={f.route}>
            {f.name ?? f.route}
          </option>
        ))}
      </Select>
    </Label>
  );
};

export default FacilityPicker;

