import { styled } from 'styled-components';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BsFillInfoCircleFill } from 'react-icons/bs';

export const TooltipSymbol = styled.div`
    color: ${(props) => props.theme.palette.text.fg};
    font-size: 20px;
    cursor: pointer;
    margin-bottom: 2px;
    &:hover {
      color: #97d2ef;
    `;

export const TooltipBox = styled.div<{
  $tooltipPosition?: { top: number; left: number } | null;
  $fixed?: boolean;
}>`
  position: ${(props) => (props.$fixed ? 'fixed' : 'absolute')};
  top: ${(props) =>
    props.$tooltipPosition ? `${props.$tooltipPosition.top}px` : '40px'};
  left: ${(props) =>
    props.$tooltipPosition ? `${props.$tooltipPosition.left}px` : '-10px'};
  width: 400px;
  padding: 25px;
  border-radius: 4px;
  color: ${(props) => props.theme.palette.text.fg};
  background: #97d2ef;
  box-shadow: 0 0 5px 0 #0000004c;
  z-index: 9999;
  white-space: normal;
  font-size: 16px;
  font-weight: 500;
  &:before {
    content: '';
    width: 0;
    height: 0;
    left: 10px;
    top: -10px;
    position: absolute;
    border: 10px solid #97d2ef;
    transform: rotate(135deg);
    transition: border 0.3s ease-in-out;
    z-index: 9999;
  }
`;

export const TooltipWrapper = styled.div<{}>`
  position: relative;
`;

export enum TooltipMode {
  HOVER,
  CLICK,
}

interface Props {
  mode: TooltipMode;
  fixed?: boolean;
  tooltipText: string;
}

const TooltipComponent: React.FC<Props> = ({
  mode,
  tooltipText,
  fixed = true,
}) => {
  const [tooltipPosition, setTooltipPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);

  const [isOpen, setIsOpen] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const calculatePosition = useCallback((target: HTMLElement) => {
    const rect = target.getBoundingClientRect();
    setTooltipPosition({
      top: rect.bottom + window.scrollY + 8,
      left: rect.left + window.scrollX,
    });
  }, []);

  const toggleTooltip = (event: React.MouseEvent<HTMLDivElement>) => {
    if (mode === TooltipMode.CLICK) {
      setIsOpen((prev) => !prev);
      if (fixed) calculatePosition(event.currentTarget as HTMLElement);
    }
  };

  const handleHover = (event: React.MouseEvent<HTMLDivElement>) => {
    if (mode === TooltipMode.HOVER && !isOpen) {
      setIsOpen(true);
      if (fixed) calculatePosition(event.currentTarget as HTMLElement);
    }
  };

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        setTooltipPosition(null);
      }
    },
    [setIsOpen]
  );

  useEffect(() => {
    if (mode === TooltipMode.CLICK) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [mode, handleClickOutside]);


  return (
    <TooltipWrapper
      ref={tooltipRef}
      onClick={toggleTooltip}
      onMouseOver={handleHover}
      onMouseLeave={() => mode === TooltipMode.HOVER && setIsOpen(false)}
    >
      <TooltipSymbol>
        <BsFillInfoCircleFill />
      </TooltipSymbol>
      {isOpen && (
        <TooltipBox $fixed={fixed} $tooltipPosition={tooltipPosition}>
          {tooltipText}
        </TooltipBox>
      )}
    </TooltipWrapper>
  );
};

export default TooltipComponent;

