import styled, { css, keyframes } from 'styled-components';

const animation = keyframes`
0%, 100% {
  box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0),
    1.8em -1.8em 0 0em rgba(137, 87, 160, 0.3),
    2.5em 0em 0 0em rgba(137, 87, 160, 0.3),
    1.75em 1.75em 0 0em rgba(137, 87, 160, 0.3),
    0em 2.5em 0 0em rgba(137, 87, 160, 0.3),
    -1.8em 1.8em 0 0em rgba(137, 87, 160, 0.3),
    -2.6em 0em 0 0em rgba(137, 87, 160, 0.7),
    -1.8em -1.8em 0 0em #8957a0;
}
12.5% {
  box-shadow: 0em -2.6em 0em 0em #8957a0,
    1.8em -1.8em 0 0em rgba(255, 255, 255, 0),
    2.5em 0em 0 0em rgba(137, 87, 160, 0.3),
    1.75em 1.75em 0 0em rgba(137, 87, 160, 0.3),
    0em 2.5em 0 0em rgba(137, 87, 160, 0.3),
    -1.8em 1.8em 0 0em rgba(137, 87, 160, 0.3),
    -2.6em 0em 0 0em rgba(137, 87, 160, 0.3),
    -1.8em -1.8em 0 0em rgba(137, 87, 160, 0.7);
}
25% {
  box-shadow: 0em -2.6em 0em 0em rgba(137, 87, 160, 0.7),
    1.8em -1.8em 0 0em #8957a0,
    2.5em 0em 0 0em rgba(255, 255, 255, 0),
    1.75em 1.75em 0 0em rgba(137, 87, 160, 0.3),
    0em 2.5em 0 0em rgba(137, 87, 160, 0.3),
    -1.8em 1.8em 0 0em rgba(137, 87, 160, 0.3),
    -2.6em 0em 0 0em rgba(137, 87, 160, 0.3),
    -1.8em -1.8em 0 0em rgba(137, 87, 160, 0.3);
}
37.5% {
  box-shadow: 0em -2.6em 0em 0em rgba(137, 87, 160, 0.3),
    1.8em -1.8em 0 0em rgba(137, 87, 160, 0.7),
    2.5em 0em 0 0em #8957a0,
    1.75em 1.75em 0 0em rgba(255, 255, 255, 0),
    0em 2.5em 0 0em rgba(137, 87, 160, 0.3),
    -1.8em 1.8em 0 0em rgba(137, 87, 160, 0.3),
    -2.6em 0em 0 0em rgba(137, 87, 160, 0.3),
    -1.8em -1.8em 0 0em rgba(137, 87, 160, 0.3);
}
50% {
  box-shadow: 0em -2.6em 0em 0em rgba(137, 87, 160, 0.3),
    1.8em -1.8em 0 0em rgba(137, 87, 160, 0.3),
    2.5em 0em 0 0em rgba(137, 87, 160, 0.7),
    1.75em 1.75em 0 0em #8957a0,
    0em 2.5em 0 0em rgba(255, 255, 255, 0),
    -1.8em 1.8em 0 0em rgba(137, 87, 160, 0.3),
    -2.6em 0em 0 0em rgba(137, 87, 160, 0.3),
    -1.8em -1.8em 0 0em rgba(137, 87, 160, 0.3);
}
62.5% {
  box-shadow: 0em -2.6em 0em 0em rgba(137, 87, 160, 0.3),
    1.8em -1.8em 0 0em rgba(137, 87, 160, 0.3),
    2.5em 0em 0 0em rgba(137, 87, 160, 0.3),
    1.75em 1.75em 0 0em rgba(137, 87, 160, 0.7),
    0em 2.5em 0 0em #8957a0,
    -1.8em 1.8em 0 0em rgba(255, 255, 255, 0),
    -2.6em 0em 0 0em rgba(137, 87, 160, 0.3),
    -1.8em -1.8em 0 0em rgba(137, 87, 160, 0.3);
}
75% {
  box-shadow: 0em -2.6em 0em 0em rgba(137, 87, 160, 0.3),
    1.8em -1.8em 0 0em rgba(137, 87, 160, 0.3),
    2.5em 0em 0 0em rgba(137, 87, 160, 0.3),
    1.75em 1.75em 0 0em rgba(137, 87, 160, 0.3),
    0em 2.5em 0 0em rgba(137, 87, 160, 0.7),
    -1.8em 1.8em 0 0em #8957a0,
    -2.6em 0em 0 0em rgba(255, 255, 255, 0),
    -1.8em -1.8em 0 0em rgba(137, 87, 160, 0.3);
}
87.5% {
  box-shadow: 0em -2.6em 0em 0em rgba(137, 87, 160, 0.3),
    1.8em -1.8em 0 0em rgba(137, 87, 160, 0.3),
    2.5em 0em 0 0em rgba(137, 87, 160, 0.3),
    1.75em 1.75em 0 0em rgba(137, 87, 160, 0.3),
    0em 2.5em 0 0em rgba(137, 87, 160, 0.3),
    -1.8em 1.8em 0 0em rgba(137, 87, 160, 0.7),
    -2.6em 0em 0 0em #8957a0,
    -1.8em -1.8em 0 0em rgba(255, 255, 255, 0);
}
`;

const Spinner = styled.div<{ $dotSize?: number }>`
  margin: 4em;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  animation: ${animation} 1s linear infinite;

  ${({ $dotSize }) =>
    $dotSize !== undefined && $dotSize !== null
      ? css`
          font-size: ${$dotSize}px;
        `
      : undefined}
`;

export default Spinner;
