import React from 'react';
import { generatePath, useLocation, useRouteMatch } from 'react-router';
import { FacilityPageProps } from 'topdefinitions';
import Routes from 'constants/Route';
import SubNavBar from 'components/SubNavBar';
import Link from 'components/Link';
import { styled } from 'styled-components';
import Button, { ButtonColors } from 'components/input/Button';
import useModalStack from 'components/Modal/useModalStack';
import GroupModal from './modals/GroupModal';

const ConfigNav = styled(SubNavBar)`
  justify-content: space-between;
`;
const Left = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 20px;
`;
const Right = styled.div`
  align-self: flex-end;
`;

const ConfigBar: React.FC = () => {
  const rm = useRouteMatch() as any as FacilityPageProps['match'];
  const location = useLocation();
  const LocRoute = {
    Groups: Routes.ConfigurationGroups,
    Zones: Routes.ConfigurationZones,
  } as any;
  for (const k in LocRoute) {
    LocRoute[k] = generatePath(LocRoute[k], rm.params);
  }
  const path = location.pathname;
  const modal = useModalStack();

  return (
    <ConfigNav>
      <Left>
        <Link
          $active={path === LocRoute.Groups}
          $secondary
          to={LocRoute.Groups}
        >
          All Groups
        </Link>
        <Link $active={path === LocRoute.Zones} $secondary to={LocRoute.Zones}>
          All Zones
        </Link>
      </Left>
        {path === LocRoute.Groups ? (
      <Right>
          <Button
            style={{ bottom: 'auto' }}
            color={ButtonColors.add}
            onClick={() => {
              modal.push(<GroupModal />);
            }}
          >
            Add zone group
          </Button>
      </Right>
        ) : null}
    </ConfigNav>
  );
};

export default ConfigBar;

