import React, { useEffect, useState } from 'react';
import Modal from 'components/Modal';

import Label from 'components/input/Label';
import styled from 'styled-components';
import Input from 'components/input/Input';
import RulesTable from '../tables/RulesTable';
import AddRule from '../tables/AddRule';
import Loader from 'components/Loader';
import useModalStack from 'components/Modal/useModalStack';
import ZoneMultiSelector, { ZoneSelectorOption } from '../ZoneMultiSelector';
import {
  Group,
  GroupRule,
  useConfigContext,
} from 'pages/Configuration/contexts/configurationContext';

interface GroupModalProps {
  editableGroup?: Group;
}

const ModalWrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 2.5rem;
  min-height: 600px;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const TopColumn = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;
const BottomColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const initialGroup: Group = {
  name: '',
  id: 0,
  zoneIds: [],
  rules: [],
  facilityId: 0,
};
const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px
  padding: 0;
  margin: 0;
  list-style: none;
  width: 500px;
`;
const ListItem = styled.li`
  padding: 5px;
  margin: 5px;
  background: ${(props) => props.theme.palette.secondaryLight.bg};
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  box-sizing: border-box;
  white-space: nowrap;
  letter-spacing: 0.2px;
`;

const GroupModal: React.FC<GroupModalProps> = ({ editableGroup }) => {
  const { getZones, editGroup, createGroup, isLoading } = useConfigContext();
  const zones = getZones();
  const modal = useModalStack();
  const [group, setGroup] = useState<Group>(editableGroup || initialGroup);
  const [groupName, setGroupName] = useState<string>(editableGroup?.name || '');
  const [selectedZones, setSelectedZones] = useState<string[]>(
    editableGroup?.zoneIds || []
  );

  const groupId = editableGroup?.id || 0;

  const addGroupRule = (ruleId: number, segment: number, value: string) => {
    if (
      group.rules.some(
        (rule) => rule.segment === segment && rule.configurationId === ruleId
      )
    ) {
      alert('You can only add one rule per segment');
      return;
    } else {
      var rules = [
        ...group.rules,
        {
          id: 0,
          groupId: groupId,
          configurationId: ruleId,
          value: value,
          segment: segment,
        },
      ];
    }

    setGroup({ ...group, rules: rules });
  };

  const updateGroupRule = (updatedRule: GroupRule) => {
    console.log('updateGroupRule', updatedRule);
    setGroup({
      ...group,
      rules: group.rules.map((rule) =>
        rule.id === updatedRule.id ? updatedRule : rule
      ),
    });
  };

  const deleteGroupRule = (ruleId: number) => {
    setGroup({
      ...group,
      rules: group.rules.filter((rule) => rule.id !== ruleId),
    });
  };

  const handleSubmit = async () => {
    if (!groupName || selectedZones.length === 0) {
      alert('Please fill all fields');
      return;
    }

    if (editableGroup) {
      await editGroup(group);
    } else {
      await createGroup(group);
    }
    modal.pop();
  };

  const selected = selectedZones
    .map((zoneId) => {
      const zone = zones.find((zone) => zone.gid === zoneId);
      return zone ? { id: zone.gid, name: zone.name } : null;
    })
    .filter((zone): zone is ZoneSelectorOption => zone !== null);

  useEffect(() => {
    setGroup((prevGroup) => ({
      ...prevGroup,
      name: groupName,
      zoneIds: selectedZones,
    }));
  }, [groupName, selectedZones]);

  return (
    <ModalWrapper
      title={editableGroup ? 'Edit Group' : 'Create New Group'}
      buttons={[
        {
          disabled:
            groupName.length === 0 || selectedZones.length === 0 || isLoading,
          label: editableGroup ? 'Save Changes' : 'Create',
          onClick: handleSubmit,
          dontPopAfterClick: true,
        },
      ]}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <Wrapper>
          <TopColumn>
            <Label label="Group Name">
              <Input
                type="text"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                placeholder="Enter group name"
              />
            </Label>
            <Label label="Zones">
              <ZoneMultiSelector
                options={zones.map((zone) => ({
                  id: zone.gid,
                  name: zone.name,
                }))}
                selected={selected}
                onChange={(selected) =>
                  setSelectedZones(selected.map((zone) => zone.id.toString()))
                }
                placeholder={
                  zones.length === 0 ? 'Loading zones...' : 'Select Zones'
                }
              />
            </Label>
            <Label label="Selected Zones:">
              <List>
                {selected.length
                  ? selected.map((item) => <ListItem key={item.id}>{item.name}</ListItem>)
                  : null}
              </List>
            </Label>
          </TopColumn>
          <BottomColumn>
            <h5>Add group rules</h5>
            <AddRule createRule={addGroupRule} ruleList={group.rules} />
            <RulesTable
              ruleList={group.rules}
              updateRule={updateGroupRule}
              deleteRule={deleteGroupRule}
            />
          </BottomColumn>
        </Wrapper>
      )}
    </ModalWrapper>
  );
};

export default GroupModal;

