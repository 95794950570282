import Link from 'components/Link';
import SubNavBar from 'components/SubNavBar';
import Routes from 'constants/Route';
import OccupancyPicker from 'containers/OccupancyPicker';
import React from 'react';
import { generatePath, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { FacilityPageProps } from 'topdefinitions';

const Nav = styled(SubNavBar)`
  justify-content: space-between;
`;
const Left = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 20px;
`;
const Right = styled.div`
  align-self: flex-end;
`;

const ForecastBar: React.FC = () => {
  const rm = useRouteMatch() as any as FacilityPageProps['match'];
  const LocRoute = {
    Revenue: Routes.FacilityForecastRevenue,
    Demand: Routes.FacilityForecastDemand,
    BookingPattern: Routes.FacilityForecastBookingPattern,
    PriceComparison: Routes.FacilityForecastPriceComparison,
    NewBookings: Routes.FacilityForecastNewBookings,
    Dashboard: Routes.FacilityDashboard,
    Occupancy: Routes.FacilityOccupancy,
    Performance: Routes.FacilityPerformance,
  } as any;
  for (const k in LocRoute) {
    LocRoute[k] = generatePath(LocRoute[k], rm.params);
  }

  console.log('RM', rm, LocRoute);
  var path = window.location.pathname;
  return (
    <Nav>
      <Left>
        <Link
          $active={path === LocRoute.Revenue}
          $secondary
          to={LocRoute.Revenue}
        >
          Revenue
        </Link>
        <Link
          $active={path === LocRoute.Demand}
          $secondary
          to={LocRoute.Demand}
        >
          Demand
        </Link>
        <Link
          $active={path === LocRoute.NewBookings}
          $secondary
          to={LocRoute.NewBookings}
        >
          New bookings
        </Link>
        <Link
          $active={path === LocRoute.Occupancy}
          $secondary
          to={LocRoute.Occupancy}
        >
          Occupancy
        </Link>
        <Link
          $active={path === LocRoute.BookingPattern}
          $secondary
          to={LocRoute.BookingPattern}
        >
          Booking pace
        </Link>
        <Link
          $active={path === LocRoute.PriceComparison}
          $secondary
          to={LocRoute.PriceComparison}
        >
          Price Comparison
        </Link>
        <Link
          $active={path === LocRoute.Dashboard}
          $secondary
          to={LocRoute.Dashboard}
        >
          Dashboard
        </Link>
        <Link
          $active={path === LocRoute.Performance}
          $secondary
          to={LocRoute.Performance}
        >
          Performance
        </Link>
      </Left>

      <Right>
        <OccupancyPicker />
      </Right>
    </Nav>
  );
};

export default ForecastBar;

