import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch} from 'react-router';
import {
  setSpecialSelect,
  uiSetAllSelected,
  zoneChangeActive,
} from 'store/ui/action';
import { selectUISettingSites } from 'store/uiSettings/selectors';
import {
  getActualCurrentZones,
  getSpecialSelect,
  selectAvailableZones,
} from 'store/zone/selector';
import styled from 'styled-components';
import Routes from '../constants/Route';
import { FacilityPageProps } from '../topdefinitions';
import { selectUiAllSelected } from 'store/ui/selector';
import MultiSelect, { MultiSelectOption } from 'components/input/MultiSelect';
import {
  useConfigContext,
  Group,
} from 'pages/Configuration/contexts/configurationContext';
import Label from 'components/input/Label';

const LabelWrapper = styled(Label)`
  flex-direction: row;
  margin-right: 0;
  display: grid;
  grid-template-columns: 50px 225px;
  z-index: 90;
  align-items: center;
  justify-content: center;
`;

export const destinationsData = {
  '/forecast/revenue': [Routes.FacilityForecastRevenue],
  '/forecast/demand': [Routes.FacilityForecastDemand],
  '/schedule': [Routes.FacilitySchedule],
  '/adjustments': [Routes.FacilityAdjustments],
  '/analysis': [Routes.FacilityAnalysis],
  '/': [Routes.FacilityForecastRevenue],
} as { [k: string]: string[] };
export const destinations = Object.keys(destinationsData).map((k) => ({
  dest: k,
  path: destinationsData[k],
}));

const ZonePicker: FC = () => {
  const dispatch = useDispatch();

  const activeZones = useSelector(getActualCurrentZones);
  const specialSelect = useSelector(getSpecialSelect);
  const zones = useSelector(selectAvailableZones);

  // pick out unique facility routes.
  const facilityRoutes = zones
    .map((zn) => zn.sitePath ?? '')
    .reduce(
      (acc, cur) =>
        acc.find((existing) => existing === cur) ? acc : [...acc, cur],
      [] as string[]
    );

  const uiSetSites = useSelector(selectUISettingSites);
  const allSelected = useSelector(selectUiAllSelected);
  const match = useRouteMatch<FacilityPageProps['match']['params']>();
  const { groups, setFacilityId } = useConfigContext();

  const facilityId = match?.params?.facilityId;
  setFacilityId(facilityId!);

  const facilityZones = uiSetSites.filter((f) => f.path == facilityId);

  const specialZonePicker = groups;
  const handleSpecialSelect = (sel: Group) => {
    if (specialSelect.includes(sel.id)) {
      dispatch(setSpecialSelect(specialSelect.filter((f) => f != sel.id)));
    } else {
      dispatch(setSpecialSelect([sel.id]));
    }
  };

  const checkSpecialSelect = (zoneIds: (string | number)[]) => {
    var selected: number[] = [];
    specialZonePicker.forEach((sp) => {
      var val = sp.zoneIds.reduce((acc, curr) => {
        if (!zoneIds.find((x) => x == curr)) {
          return false;
        }
        return acc;
      }, true);
      if (val) {
        selected.push(sp.id);
      }
      return sp;
    });
    return selected;
  };

  return (
    <LabelWrapper row label="Zone:">
      <MultiSelect
        selectAll={true}
        selectAllLabel={'All zones'}
        facilityID={facilityId}
        allSelected={allSelected}
        extended={true}
        onSpecialSelect={handleSpecialSelect}
        onChangeAll={(all: boolean) => {
          console.log('AAAALL', all);
          if (!!all) {
            dispatch(setSpecialSelect(specialZonePicker.map((f) => f.id)));
          } else {
            console.log('NOT ALL', specialSelect);
            dispatch(setSpecialSelect([]));
          }
          dispatch(uiSetAllSelected(all));
        }}
        specialSelected={specialSelect}
        specialSelectOptions={specialZonePicker}
        selected={activeZones.reduce(
          (acc, curr) => [
            ...acc,
            {
              name: curr.name,
              id: curr.gid,
              route: '',
            },
          ],
          [] as MultiSelectOption[]
        )}
        available={zones
          .filter((f) => f.sitePath === facilityId)
          .reduce(
            (acc, curr) => [
              ...acc,
              {
                name: curr.name,
                id: curr.gid,
                route: '',
              },
            ],
            [] as MultiSelectOption[]
          )}
        onChange={(list: MultiSelectOption[]) => {
          // pick the longest matching path-type
          const routeType = destinations.reduce(
            (prev, cur) =>
              match?.path.indexOf(cur.dest) === -1
                ? prev
                : prev.dest.length < cur.dest.length
                ? cur
                : prev,
            { dest: '/', path: destinationsData['/'] }
          );
          let values = list.map((f) => f.id);
          var zoneIds = zones.reduce((acc, curr) => {
            if (values.includes(curr.gid)) {
              return [...acc, curr.id];
            }
            return acc;
          }, [] as number[]);
          if (specialSelect.length > 0) {
            const activeSpecials = checkSpecialSelect(values);
            if (activeSpecials.length !== specialSelect.length) {
              dispatch(setSpecialSelect(activeSpecials));
            }
          }
          //console.warn('Untested path for ALL segments');
          dispatch(zoneChangeActive(zoneIds));
        }}
      >
        {facilityZones.map((site) => {
          return (
            <React.Fragment key={site.id}>
              <option value={'site:' + site.path}>All zones</option>
              {zones
                .filter((zone) => zone.sitePath === site.path)
                .map((zone) => (
                  <option key={zone.gid} value={zone.gid}>
                    {zone.name}
                  </option>
                ))}
            </React.Fragment>
          );
        })}

        {/* <option key={'__ALLZONES'} value={''}>
          All zones
        </option>
        {zones &&
          zones.map((demozone) => {
            return (
              <option key={demozone.id} value={demozone.id}>
                {demozone.name}
              </option>
            );
          })} */}
      </MultiSelect>
    </LabelWrapper>
  );
};

export default ZonePicker;

