import React, { useMemo } from 'react';
import useModalStack from 'components/Modal/useModalStack';
import styled from 'styled-components';
import {
  SegmentType,
  useConfigContext,
  ZoneRule,
} from '../contexts/configurationContext';
import ZoneModal from './modals/ZoneModal';
import { Zone, Zones } from 'store/zone/reducer';
import { segmentLabels } from './tables/AddRule';
import Loader from 'components/Loader';
import { StyledTable, TableButton } from './ConfigGroups';

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`;
interface ActiveZone {
  name: string;
  segment: number;
  configurationId: number;
}

const ConfigZones: React.FC = () => {
  const { getZones, rules, isLoading } = useConfigContext();
  const zones = getZones();
  const modal = useModalStack();

  // Helper function to get rule name by configurationId
  const getRuleName = (configurationId: number): string => {
    const matchedRule = rules.find((r) => r.id === configurationId);
    return matchedRule?.name || 'Unknown Rule';
  };

  // Memoize column rules to avoid recalculating on each render
  const columnRules = useMemo(() => {
    const uniqueRules: ActiveZone[] = [];
    zones.forEach((zone) => {
      zone.rules!.forEach((rule) => {
        if (
          !uniqueRules.some(
            (r) =>
              r.configurationId === rule.configurationId &&
              r.segment === rule.segment
          )
        ) {
          uniqueRules.push({
            configurationId: rule.configurationId,
            name: getRuleName(rule.configurationId),
            segment: rule.segment,
          });
        }
      });
    });
    return uniqueRules;
  }, [zones, rules]);

  // Memoize configured zones to avoid recalculating on each render
  const configuredZones = useMemo(() => {
    return zones.map((zone) => {
      const appliedRules = zone.rules!.reduce<ZoneRule[]>((acc, rule) => {
        if (rule.groupId) {
          if (
            !acc.some(
              (r) =>
                r.configurationId === rule.configurationId &&
                r.segment === rule.segment
            )
          ) {
            acc.push(rule);
          }
        } else {
          return [
            ...acc.filter(
              (r) =>
                r.configurationId !== rule.configurationId ||
                r.segment !== rule.segment
            ),
            rule,
          ];
        }
        return acc;
      }, [] as ZoneRule[]);
      return { ...zone!, rules: appliedRules || [] };
    }) as Zones;
  }, [zones]);

  // Define table columns
  const columns = useMemo(() => {
    const baseColumns = [
      {
        header: 'Zone Name',
        accessorKey: 'name',
        minSize: 200,
        maxSize: 350,
      },
    ];

    const ruleColumns = columnRules.map((columnRule) => ({
      header: `${columnRule.name} - ${
        segmentLabels[columnRule.segment as SegmentType]
      }`,
      minSize: 300,
      cell: (props: { row: { original: Zone } }) => {
        console.log(columnRule);
        const rule = props.row.original.rules?.find(
          (r) =>
            r.configurationId === columnRule.configurationId &&
            r.segment === columnRule.segment
        );
        return rule?.value || '';
      },
    }));

    const editColumn = {
      header: 'Edit',
      accessorKey: 'id',
      cell: (props: { row: { original: { id: number } } }) => (
        <TableButton
          onClick={() => {
            const zone = zones.find((z) => z.id === props.row.original.id);
            if (zone) {
              modal.push(<ZoneModal editZone={zone} />);
            }
          }}
        >
          Edit
        </TableButton>
      ),
    };

    return [...baseColumns, ...ruleColumns, editColumn];
  }, [columnRules, modal, zones]);
  if (!configuredZones) return null;
  return (
    <Wrapper>
      {isLoading ? (
        <Loader />
      ) : (
        <StyledTable asList data={configuredZones} columns={columns} />
      )}
    </Wrapper>
  );
};

export default ConfigZones;

