import React, { FC, CSSProperties } from 'react';
import styled from 'styled-components';

const TextSpan = styled.span``;
const TheLabel = styled.label`
  display: inline-flex;
  flex-direction: column;
  font: inherit;
  font-weight: bold;
  font-size: 16px;
  position: relative;

  & > ${TextSpan}} {
    margin-bottom: 10px;
  }
`;

const RowLabel = styled(TheLabel)`
  flex-direction: row;
  position: relative;
  align-items: center;

  & > ${TextSpan} {
    margin-bottom: 0;
    margin-right: 10px;
  }
`;
const RightLabel = styled.span`
  position: absolute;
  right: 0;
`;

interface Props {
  label: string | JSX.Element;
  subLabel?: string | JSX.Element;
  className?: string;
  labelStyle?: CSSProperties;
  row?: boolean;
  hasRightLabel?: boolean;
  rightLabel?: string;
}

const Label: FC<Props> = ({
  label,
  subLabel,
  hasRightLabel = false,
  rightLabel,
  children,
  className,
  labelStyle,
  row = false,
}) => {
  if (row) {
    return (
      <RowLabel className={className} style={labelStyle}>
        <TextSpan>{label}</TextSpan>
        {hasRightLabel && <RightLabel>{rightLabel}</RightLabel>}
        {children}
      </RowLabel>
    );
  }

  return (
    <TheLabel className={className} style={labelStyle}>
      <span>{label}</span>
      {hasRightLabel && <RightLabel>{rightLabel}</RightLabel>}
      {children}
    </TheLabel>
  );
};

export default Label;
