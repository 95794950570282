export const GlobalStoreKeys = {
    occupancyMode: 'occupancyMode',
  } as const;
  
  // Define the type for the global store keys
  type GlobalStoreKey = keyof typeof GlobalStoreKeys;
  
  // Define the global store as a dynamic object based on `GlobalStoreKeys`
  const globalStore: Record<GlobalStoreKey, any> = {
    occupancyMode: 1, // Initial value for `occupancyMode`
  };
  
  // Utility to get a value from the global store
  export const getGlobalValue = <T>(key: GlobalStoreKey): T => {
    return globalStore[key] as T;
  };
  
  // Utility to set a value in the global store
  export const setGlobalValue = <T>(key: GlobalStoreKey, value: T): void => {
    globalStore[key] = value;
  };
  