import * as React from 'react';
import { Redirect, Route } from 'react-router';

import Routes from './Route';

import DemandPage from '../pages/Demand';
import Revenue from '../pages/Revenue';
import PricingPage from '../pages/Pricing';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { defaultTheme } from '../constants/Theme';
import JTest from '../pages/Scratchpad/JTest';
import { ModalProvider } from '../components/Modal/useModalStack';

const GlobalStyles = createGlobalStyle`
* {
    box-sizing: border-box;
}
`;

const AppWrap = styled.div`
  background-color: ${({ theme }) => theme.palette.text.bg};
  font-family: 'Source Sans 3', sans-serif;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.text.fg};
`;

export default () => {
  // <Route path={'/facilities/{:id}'} component={TestC} />
  // <Route exact path={Routes.JTest} component={JTest} />
  // <Route path={'/{:rest}'} component={RestC} />
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <ModalProvider>
        <AppWrap>
          <Route exact path={Routes.Revenue} component={Revenue} />
          <Route exact path={Routes.Pricing} component={PricingPage} />
          <Route exact path={Routes.Demand} component={DemandPage} />
        </AppWrap>
      </ModalProvider>
    </ThemeProvider>
  );
};
