export const RouteParam = {} as const;

const Route = {
  FacilityForecastRevenue: '/facility/:facilityId/forecast/revenue',
  FacilityForecastDemand: '/facility/:facilityId/forecast/demand',
  FacilityForecastBookingPattern:
    '/facility/:facilityId/forecast/booking-pattern',
  FacilityForecastPriceComparison:
    '/facility/:facilityId/forecast/price-comparison',
  FacilityForecastNewBookings: '/facility/:facilityId/forecast/new-bookings',
  FacilityDashboard: '/facility/:facilityId/forecast/dashboard',
  FacilityOccupancy: '/facility/:facilityId/forecast/occupancy',
  FacilityPerformance: '/facility/:facilityId/forecast/performance',
  FacilityPricing: '/facility/:facilityId/pricing',
  FacilityAdjustments: '/facility/:facilityId/adjustments',
  FacilitySchedule: '/facility/:facilityId/schedule',
  FacilityScheduleZones: '/facility/:facilityId/schedule/zones',
  FacilityScheduleSeasons: '/facility/:facilityId/schedule/seasons',
  FacilityAnalysis: '/facility/:facilityId/analysis',
  Facilities: '/facility/:facilityId',
  FacilityForecast: '/facility/:facilityId/forecast',
  AdminUsers: '/admin/users',
  AdminDates: '/admin/dates',
  JTest: '/jtest',
  EVPriceGraph: '/facility/:facilityId/ev/preview',
  EVPriceZGraph: '/facility/:facilityId/:zoneId/ev/preview',
  Configuration: '/facility/:facilityId/configuration',
  ConfigurationGroups: '/facility/:facilityId/configuration/groups',
  ConfigurationZones: '/facility/:facilityId/configuration/zones',
} as const;

export const FacilityRoutes = [
  Route.FacilityPricing,
  Route.EVPriceGraph,
  Route.EVPriceZGraph,
  Route.Facilities,
  Route.FacilityAnalysis,
  Route.FacilitySchedule,
  Route.Configuration,
  Route.ConfigurationZones,
];

export default Route;
