import { Dropdown } from 'components/input/Dropdown';
import TooltipComponent, { TooltipMode } from 'components/Tooltip';
import React, { useState, useRef, useEffect } from 'react';
import { BsChevronDown, BsFillInfoCircleFill } from 'react-icons/bs';
import { styled } from 'styled-components';

export const RuleDropdownWrapper = styled(Dropdown.Wrapper)`
  z-index: 2;
`;

export const RuleDropdown = styled(Dropdown.Select)`
  z-index: 999;
`;

export const RuleDropdownToggle = styled(Dropdown.Toggle)`
  z-index: 2;
`;

export const InfoSymbol = styled(BsFillInfoCircleFill)<{
  $tooltipOpen?: boolean;
}>`
  color: ${(props) =>
    props.$tooltipOpen ? '#97d2ef' : props.theme.palette.text.fg};
  font-size: 20px;
  cursor: pointer;
  &:hover {
    color: #97d2ef;
  `;
export const Tooltip = styled.div<{
  $tooltipPosition?: { top: number; left: number } | null;
  $position?: string;
}>`
  position: ${(props) => (props.$position ? props.$position : 'fixed')};
  top: ${(props) =>
    props.$tooltipPosition ? `${props.$tooltipPosition.top}px` : '0'};
  left: ${(props) =>
    props.$tooltipPosition ? `${props.$tooltipPosition.left}px` : '0'};
  max-width: 400px;
  background-color: white;
  z-index: 9999;
  padding: 25px;
  color: ${(props) => props.theme.palette.text.fg};
  background: #97d2ef;
  border: 0.15rem solid #97d2ef;
  box-shadow: 0 0 5px 0 #0000004c;
  border-radius: 4px;
  white-space: normal;
`;

export interface RuleSelectorProps {
  options: { id: number; name: string; info?: string }[];
  value: number;
  onChange: (id: number) => void;
  placeholder?: string;
  scrollable?: boolean;
  maxHeight?: string;
  disabled?: boolean;
}

const RuleSelector: React.FC<RuleSelectorProps> = ({
  options,
  value,
  onChange,
  placeholder = 'Select an option',
  scrollable = true,
  maxHeight = '300px',
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    const clickedOutsideDropdown =
      wrapperRef.current && !wrapperRef.current.contains(event.target as Node);

    if (isOpen && clickedOutsideDropdown) {
      console.log("1");
      setIsOpen(false);
    }
  };

  const handleSelect = (id: number) => {
    onChange(id);
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);
  return (
    <RuleDropdownWrapper $disabled={disabled} ref={wrapperRef}>
      <RuleDropdownToggle
        disabled={disabled}
        onClick={() => (!disabled ? setIsOpen((prev) => !prev) : undefined)}
      >
        <div>
          {!disabled
            ? options.find((option) => option.id === value)?.name
            : placeholder}
        </div>
        <BsChevronDown size="12px" />
      </RuleDropdownToggle>
      {isOpen && (
        <RuleDropdown
          role="listbox"
          $scrollable={scrollable}
          $maxHeight={maxHeight}
        >
          {options.map((option) => (
            <Dropdown.Option
              key={option.id}
              $isSelected={value === option.id}
              onClick={(e) => {
                e.stopPropagation();
                console.log("2");
                handleSelect(option.id);
              }} 
            >
              {option.name}

              <TooltipComponent mode={TooltipMode.HOVER} fixed tooltipText={option.info!} />
            </Dropdown.Option>
          ))}
        </RuleDropdown>
      )}
    </RuleDropdownWrapper>
  );
};

export default RuleSelector;

