import Button from 'components/input/Button';
import Input from 'components/input/Input';
import Label from 'components/input/Label';
import useModalStack from 'components/Modal/useModalStack';
import PriceProductAIModal from 'pages/Pricing/PriceProductAIModal';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { PricingGroup, PricingRange, Zone, ZoneFlag } from 'store/zone/reducer';
import styled from 'styled-components';
import PricingRangeInput from './PricingRangeInput';
import usePricingEditorReducer, { actions } from './usePricingEditorState';
import {
  NavTabWrapper,
  NavTab,
  navTabActiveClass,
} from '../../components/NavBar';
import NewRangeModal from './NewRangeModal';
import { getDurationTitle } from 'util/pricing';
import { hasFlag } from 'util/facility';
import moment from 'moment';
import PTDatePicker from 'components/DateRangePicker/DatePicker';
import { EmptyGUID } from 'store/ui/uiutil';
import { CardType } from 'components/Seasons';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import TooltipComponent, { TooltipMode } from 'components/Tooltip';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const nameClass = 'name';

const Inputs = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 100px;
  grid-row-gap: 10px;
  padding-left: 110px;

  .${nameClass} {
    grid-area: 1/1/2/2;
  }
`;

const InTabInputs = styled.div`
  flex: 1;
  position: relative;
  top: -20px;
  display: grid;
  grid-template-areas:
    'a b'
    'c c'
    'd d';
  grid-column-gap: 100px;
  grid-row-gap: 10px;

  .${nameClass} {
    grid-area: 1/1/2/2;
  }
`;

const AskAiContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  span {
    margin-bottom: 5px;
  }
`;

const InfoSymbol = styled(BsFillInfoCircleFill)`
  color: #3b4365;
  font-size: 20px;
  cursor: pointer;
  &:hover {
    color: #97d2ef;
  }
`;

const FillerTip = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #97d2ef;
  position: absolute;
  top: 30px;
  left: 10px;
  transform: translateX(-50%);
  z-index: 3;
`;

const DateContainer = styled.div`
  display: flex;
  gap: 20px;
  grid-area: c;
  padding-top: 10px;
  position: relative;
`;

const OccupancyContainer = styled.div`
  display: flex;
  gap: 20px;
  grid-area: d;
  padding-top: 10px;
  flex-direction: column;
`;

const OccupancyRow = styled.div`
  display: flex;
  gap: 20px;
`;

const Segments = styled.div`
  grid-area: 2/1/3/3;
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  padding-top: 20px;
`;

const Segment = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.bg};
    margin-bottom: 20px;
  }
`;

const getNumberPrefixFromString = (str: string) => {
  let regRes = /^\s*([0-9]+)\s*([a-zA-Z]+)/.exec(str);
  if (regRes) {
    return { amount: parseInt(regRes[1]), unit: regRes[2] };
  }
  return null;
};

const durationOrdering = (duration: PricingRange['duration']) => {
  const amountUnit = getNumberPrefixFromString(duration);
  if (amountUnit) {
    switch (amountUnit.unit.toLowerCase()) {
      case 'hour':
      case 'hours':
        return amountUnit.amount;
      case 'day':
      case 'days':
        return amountUnit.amount * 24;
      case 'week':
      case 'weeks':
        return amountUnit.amount * 24 * 7;
    }
  }
  switch (duration) {
    case 'hour':
      return 1;
    case 'day':
      return 24;
    case 'week':
      return 24 * 7;
  }
};

const GroupNavBar = styled.div`
  padding: 10px 0;
`;

const DISC_MARGIN = 10;
const AMOUNT_DISC_ON_ROW = 6;

const DiscountWrapper = styled.div<{ width: number; amount: number }>`
  display: flex;
  flex-wrap: wrap;
  width: ${(props) => props.width}px;
  > label {
    flex: 1;
    max-width: 50%;
    flex-grow: ${(props) => (props.amount >= AMOUNT_DISC_ON_ROW ? 0 : 1)};
    min-width: ${(props) =>
      (props.width - AMOUNT_DISC_ON_ROW * DISC_MARGIN * 2) /
      AMOUNT_DISC_ON_ROW}px;
    margin: 10px ${DISC_MARGIN}px 0;
  }
`;

const PriceProductNavTabWrapper = styled(NavTabWrapper)`
  flex-direction: column;
  justify-content: flex-end;
`;
const SegmentLabelWrapper = styled.div`
  > label {
    margin: 0 ${DISC_MARGIN}px;
  }
`;

const durationSort = (a: PricingRange, b: PricingRange) =>
  durationOrdering(a.duration) - durationOrdering(b.duration);

const segments = ['Driveup', 'Booking'];

const groupKeyToSortValue = (n: number | null) =>
  n === null || n === undefined
    ? 0
    : n == 0
    ? 1
    : Math.abs(n) * 2 + (n < 0 ? 1 : 0);

interface Props {
  zone: Zone;
  priceProductEditorReducer: ReturnType<typeof usePricingEditorReducer>;
  cardType: CardType;
  readOnly?: boolean;
  visualStartDate?: string;
  visualEndDate?: string;
}

const PriceProductEditor: FC<Props> = ({
  zone,
  priceProductEditorReducer,
  cardType,
  readOnly = false,
  visualEndDate,
  visualStartDate,
}) => {
  const modalStack = useModalStack();
  const [editState, d] = priceProductEditorReducer;
  const [sourceGID, setSourceGID] = useState<string | undefined>(
    editState.sourceSpanGID ?? EmptyGUID
  );

  const [activeSegment, setActiveSegment] = useState(
    !!editState.prices.some((pr) => pr.segment.toLowerCase() === 'booking')
      ? 'booking'
      : 'driveup'
  );
  const [adjustedWidth, setAdjustedWidth] = useState(0);
  const [showInfo, setShowInfo] = useState<boolean>(false);

  useEffect(() => {
    setAdjustedWidth(document.getElementById('main-segment')?.offsetWidth!);
  }, []);

  // build a "sorted" list of groups: first put everything into a "ID" set, secondly map it to the ID and a sort-key, finally sort the set.
  const groups = [
    ...editState.prices
      .map((p) => (p.groupId === undefined ? null : p.groupId))
      .reduce((acc, cur) => (acc.add(cur), acc), new Set<number | null>()),
  ]
    .map((gid) => ({ gid, sk: groupKeyToSortValue(gid) }))
    .sort((a, b) => a.sk - b.sk);

  const segmentRanges = useMemo(
    () =>
      editState.prices
        .filter((p) => p.segment === activeSegment.toLowerCase())
        .sort(durationSort),
    [editState.prices, activeSegment]
  );

  // Create groups of surcharge ranges
  const surchargeRanges = useMemo(() => {
    return (
      editState.prices
        // First locate prices and their associated pricegroup
        .map((p) =>
          'number' !== typeof p.groupId
            ? null
            : {
                p,
                prg: editState.priceGroups.find(
                  (prg) => prg.id === p.groupId && prg.condition?.surchargeGroup
                ),
              }
        )
        // for those with groups that are surcharge groups, output the groupname, pricerange and group
        .map((pi) =>
          !pi ||
          !pi.prg ||
          !pi.prg.condition ||
          !pi.prg.condition.surchargeGroup
            ? null
            : { sn: pi.prg.condition.surchargeGroup, p: pi.p, gr: pi.prg }
        )
        // finally combined into buckets ( bliss when groupBy becomes standard )
        .reduce(
          (acc, pi) =>
            !pi
              ? acc
              : !acc[pi.sn]
              ? { ...acc, [pi.sn]: [pi.p] }
              : { ...acc, [pi.sn]: [...acc[pi.sn], pi.p] },
          {} as any
        )
    );
  }, [editState, activeSegment]) as { [n: string]: PricingRange[] };

  const isEvCharge =
    surchargeRanges &&
    surchargeRanges['electric'] &&
    surchargeRanges['electric'].length;

  console.log(surchargeRanges);

  const discountRange = editState.priceDiscounts.filter(
    (f) => f.segment === null || f.segment === activeSegment.toLowerCase()
  );
  console.log(
    activeSegment,
    editState.prices,
    segmentRanges,
    editState.priceGroups
  );

  // const bookingSegment = useMemo(
  //   () =>
  //     editState.prices
  //       .filter((p) => p.segment === 'booking')
  //       .sort(durationSort),
  //   [editState.prices]
  // );
  // const driveupSegment = useMemo(
  //   () =>
  //     editState.prices
  //       .filter((p) => p.segment === 'driveup')
  //       .sort(durationSort),
  //   [editState.prices]
  // );

  function iterateSegments(
    groupId: null | number,
    parentId: null | undefined | number
  ): PricingRange[] {
    var _segments = segmentRanges.reduce((acc, curr) => {
      if (!(curr.activationRangeId !== parentId || curr.groupId != groupId)) {
        acc.push(curr);
      }
      return acc;
    }, [] as PricingRange[]);
    return _segments.reduce(
      (acc, curr) => [...acc, curr, ...iterateSegments(groupId, curr.id)],
      [] as PricingRange[]
    );
  }

  function renderRanges(
    groupId: null | number,
    parentId: null | undefined | number
  ): JSX.Element[] {
    var group = editState.priceGroups.find((f) => f.id === groupId);
    var _segments = iterateSegments(groupId, parentId);

    return _segments.map((price) => {
      // Todo - Fix visuals with electical
      // var charge = isEvCharge
      //   ? surchargeRanges['electric'].find((f) => {
      //       return f.duration == price.duration;
      //     })
      //   : undefined;
      var charge = undefined;
      return (
        <PricingRangeInput
          key={price.id}
          title={
            (_segments.length === 1
              ? group?.name
              : price.duration +
                (!price.activationRangeId
                  ? ''
                  : `(Activated by ${
                      segmentRanges.find(
                        (sr) => sr.id === price.activationRangeId
                      )!.duration
                    })`)) ?? ''
          }
          charge={charge}
          active={!readOnly && price.active}
          toggleable={price.toggleable}
          overlay={undefined}
          min={price.min}
          max={price.max}
          onActiveChanged={(v) => d(actions.setActive(price.id, v))}
          onMaxChanged={(v) => d(actions.setMax(price.id, v))}
          onMinChanged={(v) => d(actions.setMin(price.id, v))}
        />
      );
    });
  }
  console.log(isEvCharge, activeSegment.toLowerCase() === 'driveup');

  const convertToValidNumber = (
    value: string | number | undefined,
    min: number,
    max?: number
  ) => {
    let number = Number(value);

    if (number === undefined || isNaN(number) || number < min) {
      number = 0;
    } else if (max && number > max) {
      number = max;
    }
    return Number.parseInt(number.toFixed(0));
  };

  return (
    <Wrapper>
      <Inputs></Inputs>

      <PriceProductNavTabWrapper>
        <InTabInputs>
          {cardType !== CardType.MULTIPLE && (
            <Label label="Price Product Name" className={nameClass}>
              <Input
                disabled={readOnly}
                type="text"
                value={editState.name}
                onChange={(eve) => d(actions.setName(eve.target.value))}
              />
            </Label>
          )}
          {cardType !== CardType.MULTIPLE && (
            <AskAiContainer>
              {hasFlag(zone, ZoneFlag.NoAIPrediction) && (
                <span>No AI prediction available</span>
              )}
              <Button
                disabled={
                  readOnly ||
                  !editState.startDate ||
                  hasFlag(zone, ZoneFlag.NoAIPrediction)
                }
                onClick={() => {
                  modalStack.push(
                    <PriceProductAIModal
                      priceProductEditorReducer={priceProductEditorReducer}
                      zoneId={editState.parentZoneId}
                      startDate={editState.startDate!}
                      endDate={
                        !editState.endDate
                          ? moment(editState.startDate!)
                              .add(1, 'year')
                              .format('YYYY-MM-DD')
                          : editState.endDate!
                      }
                    />
                  );
                }}
              >
                Ask the AI
              </Button>
            </AskAiContainer>
          )}
          {cardType !== CardType.MULTIPLE && (
            <DateContainer>
              <Label
                label={
                  !visualStartDate ? (
                    'Start date'
                  ) : (
                    <span>
                      <span>Start date</span>
                      <br />
                      <span style={{ fontSize: '14px' }}>
                        Active: {visualStartDate}
                      </span>
                    </span>
                  )
                }
              >
                <PTDatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={
                    !!editState.startDate
                      ? moment(editState.startDate).toDate()
                      : null
                  }
                  selectsStart
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  onChange={(date: Date) => {
                    var dateTime = moment(date).format('YYYY-MM-DD');
                    d(actions.setStartDate(dateTime));
                  }}
                  disabled={readOnly || cardType === CardType.SEASON}
                />
              </Label>

              <Label
                label={
                  !visualEndDate ? (
                    'End date'
                  ) : (
                    <span>
                      <span>End date</span>
                      <br />
                      <span style={{ fontSize: '14px' }}>
                        Active:{' '}
                        {moment(visualEndDate)
                          .subtract(1, 'days')
                          .format('YYYY-MM-DD')}
                      </span>
                    </span>
                  )
                }
              >
                <PTDatePicker
                  selectsStart
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat="yyyy-MM-dd"
                  selected={
                    !!editState.endDate
                      ? moment(editState.endDate).subtract(1, 'days').toDate()
                      : null
                  }
                  onChange={(date: Date) => {
                    if (date !== null) {
                      var dateTime = moment(date)
                        .add(1, 'days')
                        .format('YYYY-MM-DD');
                      d(actions.setEndDate(dateTime));
                    } else {
                      d(actions.setEndDate(null as any as undefined));
                    }
                  }}
                  disabled={readOnly || cardType === CardType.SEASON}
                />
              </Label>
              <TooltipComponent
                mode={TooltipMode.HOVER}
                fixed={false}
                tooltipText="A one time event will only be active for the specified
                date range. The prices within the interval will override
                any default seasonal prices. A one time event will only be
                active for the specified date range. The prices within the
                interval will override any default seasonal prices. A one
                time event will only be active for the specified date
                range. The prices within the interval will override any
                default seasonal prices. A one time event will only be
                active for the specified date range. The prices within the
                interval will override any default seasonal prices."
              />
            </DateContainer>
          )}
          <OccupancyContainer>
            <OccupancyRow>
              <Label label="Max Capacity (Amt.)">
                <Input
                  disabled={readOnly}
                  type="number"
                  value={editState.userOpts?.maxCapacity}
                  onChange={(eve) => {
                    d(
                      actions.maxCapacity(
                        convertToValidNumber(Number(eve.target.value), 0)
                      )
                    );
                  }}
                />
              </Label>
              <Label label="Booking Capacity (Amt.)">
                <Input
                  disabled={readOnly}
                  type="number"
                  value={editState.userOpts?.bookingCapacity}
                  onChange={(eve) => {
                    d(
                      actions.bookingCapacity(
                        convertToValidNumber(Number(eve.target.value), 0)
                      )
                    );
                  }}
                />
              </Label>
              <Label label="Long stay Week discount (%)">
                <Input
                  disabled={readOnly}
                  type="number"
                  value={editState.userOpts?.longStayWeekDiscount}
                  onChange={(eve) => {
                    d(
                      actions.longStayWeekDiscount(
                        convertToValidNumber(Number(eve.target.value), 0, 100)
                      )
                    );
                  }}
                />
              </Label>
            </OccupancyRow>
            <OccupancyRow>
              <Label label="Min occupancy threshold (%)">
                <Input
                  disabled={readOnly}
                  type="number"
                  value={editState.userOpts?.occupancy_target?.min}
                  onChange={(eve) => {
                    d(
                      actions.minOccupancyTarget(
                        convertToValidNumber(Number(eve.target.value), 0, 100)
                      )
                    );
                  }}
                />
              </Label>
              <Label label="Max occupancy threshold (%)">
                <Input
                  disabled={readOnly}
                  type="number"
                  value={editState.userOpts?.occupancy_target?.max}
                  onChange={(eve) => {
                    d(
                      actions.maxOccupancyTarget(
                        convertToValidNumber(Number(eve.target.value), 0, 100)
                      )
                    );
                  }}
                />
              </Label>
            </OccupancyRow>
          </OccupancyContainer>
        </InTabInputs>
        <GroupNavBar>
          {segments
            .filter((seg) =>
              editState.prices.find(
                (pr) => pr.segment.toLowerCase() === seg.toLowerCase()
              )
            )
            .map((seg) => (
              <NavTab
                key={seg}
                onClick={(e) => {
                  console.log('Test');
                  setActiveSegment(seg.toLowerCase());
                  e.preventDefault();
                }}
                to=""
                isActive={() => true}
                activeClassName={
                  seg.toLowerCase() === activeSegment
                    ? navTabActiveClass
                    : 'otherdummy'
                }
              >
                {seg}
              </NavTab>
            ))}
        </GroupNavBar>
      </PriceProductNavTabWrapper>

      <Segments id="main-segment">
        <Segment>{groups.map((r) => renderRanges(r.gid, null))}</Segment>
        {surchargeRanges['electric'] &&
          activeSegment.toLowerCase() === 'driveup' && (
            <SegmentLabelWrapper>
              <Label label="EV Charge" className={nameClass} />
              <Segment>
                {surchargeRanges['electric'].map((price) => {
                  var group = editState.priceGroups.find(
                    (f) => f.id === price.groupId
                  );
                  console.log(price);
                  return (
                    <PricingRangeInput
                      key={price.id}
                      title={getDurationTitle(
                        price.duration,
                        price.segment,
                        group
                      )}
                      active={!readOnly && price.active}
                      toggleable={price.toggleable}
                      overlay={undefined}
                      min={price.min}
                      max={price.max}
                      onActiveChanged={(v) => d(actions.setActive(price.id, v))}
                      onMaxChanged={(v) => d(actions.setMax(price.id, v))}
                      onMinChanged={(v) => d(actions.setMin(price.id, v))}
                    />
                  );
                })}
              </Segment>
            </SegmentLabelWrapper>
          )}
        {adjustedWidth != 0 && discountRange.length > 0 && (
          <Segment style={{ flexDirection: 'column' }}>
            <SegmentLabelWrapper>
              <Label label="Discount (%)" className={nameClass} />
              <DiscountWrapper
                width={adjustedWidth}
                amount={discountRange.length}
              >
                {discountRange
                  .sort((a, b) => {
                    if (a.name > b.name) {
                      return 1;
                    } else if (b.name > a.name) {
                      return -1;
                    }
                    return 0;
                  })
                  .map((f) => (
                    <Label label={f.name}>
                      <Input
                        disabled={readOnly}
                        type="number"
                        value={f.discountPercentage}
                        onChange={(e) => {
                          console.log(e.target.value);
                          var value = Number(e.target.value);
                          d(actions.discount(f.id, value));
                        }}
                      />
                    </Label>
                  ))}
              </DiscountWrapper>
            </SegmentLabelWrapper>
          </Segment>
        )}
      </Segments>
    </Wrapper>
  );
};

export default PriceProductEditor;

