import { styled } from 'styled-components';

export const BaseDropdownWrapper = styled.div<{ $disabled?: boolean }>`
  position: relative;
  flex: 1;
  width: 100%;
  pointer-events: ${(props) => (props.$disabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.$disabled ? 0.6 : 1)};
`;

export const BaseDropdown = styled.ul<{
  $scrollable: boolean;
  $maxHeight: string;
  $zIndex?: number;
}>`
  position: absolute;
  top: 42px;
  z-index: ${(props) => props.$zIndex || 1};
  border: 1px solid ${(props) => props.theme.palette.grey.fg};
  border-radius: 5px;
  width: 100%;
  background: white;
  max-height: ${(props) => props.$maxHeight};
  overflow-y: ${(props) => (props.$scrollable ? 'auto' : 'hidden')};
  padding: 0;
  margin: 0;
  list-style: none;
`;
export const BaseDropdownToggle = styled.button<{
  $zIndex?: number;
}>`
  padding: 6px 10px;
  border: 1px solid ${(props) => props.theme.palette.grey.fg};
  border-radius: 4px;
  cursor: pointer;
  background: #fff;
  white-space: nowrap;
  text-align: left;
  min-width: 240px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: ${(props) => props.$zIndex || 1};

  div {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  &:disabled {
    cursor: not-allowed;
    border: 1px solid ${(props) => props.theme.palette.disabled.fg};
    background: ${(props) => props.theme.palette.disabled.bg};
  }

  &:focus {
    outline: none;
  }
`;

export const BaseDropdownOption = styled.li<{ $isSelected: boolean }>`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid ${(props) => props.theme.palette.common.lightBorder};
  background: ${(props) =>
    props.$isSelected ? props.theme.palette.common.white : 'transparent'};
  position: relative;

  &:hover {
    background: ${(props) => props.theme.palette.common.opacityHover};
  }
`;


export const Dropdown = {
  Wrapper: BaseDropdownWrapper,
  Select: BaseDropdown,
  Toggle: BaseDropdownToggle,
  Option: BaseDropdownOption,
};
